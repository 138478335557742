<!-- eslint-disable vue/no-v-for-template-key -->

<template>
  <CRow>
    <CCol col="12" xl="12">
         <CCard class="cards">
        <CCardHeader>
          <CRow>
            <CCol class="styleHeader" col="6">
             {{$t('message.clientsSales')}}
             <br/>
              <small>{{$t('message.month')}} : {{fromDate}}</small>
            </CCol>
        </CRow>
        </CCardHeader>
        <CCardBody>
           <div class="row">
            <div class="col-md-3">
              <CFormText>
                  {{$t('message.month')}} :
                </CFormText>
                 <input
                  id="name"
                  v-model="fromDate"
                  pattern="\d{4}-\d{2}-\d{2}"
                  type="month"
                  class="filter"
                  :placeholder="$t('message.fromDate')"
                />
            </div>
            <div class="col-md-2">
              <CButton square color="btn btn-primary" v-on:click="filter" class="sub">
                    <CIcon class="c-icon" name="cil-chevron-top" /> &emsp;  {{$t('message.filter')}}
                </CButton>
            </div>
          </div>
          <hr/>
          <!-- Using the VdtnetTable component -->
          <div class="table-container" v-for="(data, mainKey) in clientsSales" :key="mainKey">
  <table v-if="data && Object.keys(data).length">
    <thead>
      <tr>
        <!-- Main Key -->
        <th rowspan="2" class="sticky-column">{{ mainKey }}</th>
        <!-- Dates -->
        <th  class="sticky-header"
          v-for="date in Object.keys(data[Object.keys(data)[0]] || {})"
          :key="date"
        >
          {{ date }}
        </th>
      </tr>
      <tr>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(dateValues, date) in data|| {}"
        :key="date"
      >
        <!-- Display Date -->
        <td style="    background: #eee;" class="sticky-column">{{ date }}</td>
        <!-- Values for Each Subkey -->
        <td
          v-for="(subData, subkey) in dateValues || {}"
          :key="subkey"
        >
          {{ subData|| 'N/A' }}
        </td>
      </tr>
    </tbody>
  </table>
  <p v-else class="no-data">No data available for {{ mainKey }}</p>
</div>

        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'InventoryReports',
  data () {
    const date = new Date()
    const formatMonthYear = (date) =>
      `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`
    return {
      fromDate: formatMonthYear(date),

      quickSearch: '',
      clientsSales: {},
      total: []
    }
  },
  created () {
    this.fetchData(this.fromDate)
  },
  methods: {
    fetchData (fromDate) {
      const fromDateFormatted = `${this.fromDate}-01`
      const toDateFormatted = `${this.fromDate}-30`
      this.$http
        .get(`${this.$hostUrl}report/customer-sales?from=${fromDateFormatted}&to=${toDateFormatted}`)
        .then((response) => {
          this.clientsSales = response.data
        })
    },
    doLoadTable (cb) {
      this.$http.getJSON(
        this.$hostUrl + 'report/orders',
        function (data) {
          cb(data.data)
        }
      )
    },
    filter ($event) {
      this.fetchData(this.fromDate)
    },
    doAfterReload (data, table) {
      console.log('data reloaded')
    },
    doCreating (comp, el) {
      console.log('creating')
    },
    doCreated (comp) {
      console.log('created')
    },
    doSearch () {
      this.$refs.table.search(this.quickSearch)
    },
    doExport (type) {
      const parms = this.$refs.table.getServerParams()
      parms.export = type
    },
    formatCode (zipcode) {
      return zipcode.split('-')[0]
    }
  }

}
</script>
<style>
.table-container {
  width: 100%;
  overflow: auto; /* Enables both horizontal and vertical scrolling */
  position: relative; /* Ensures sticky elements are in context */
  max-height: 400px; /* Adjust as needed */
  white-space: nowrap; /* Prevent table content from wrapping */
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

thead th {
  position: sticky;
  top: 0; /* Keep table header fixed at the top */
  background-color: #f4f4f4;
  z-index: 3; /* Ensure it remains above other content */
}

td:first-child,
th:first-child {
  position: sticky;
  background-color: #fff;
  z-index: 2;
}

#app.English td:first-child,
#app.English th:first-child {
  left: 0;
  right: auto;
}
#app.العربية td:first-child,
#app.العربية th:first-child {
  right: 0;
  left: auto;
}

.no-data {
  background: #f3c493;
  padding: 10px;
}
</style>
